import { Global } from '@emotion/core';
import '@fontsource/oswald';
import '@fontsource/oswald/300.css';
import { Box, Flex, Link, Text } from '@theme-ui/components';
import SiteNavigation from 'components/molecules/SiteNavigation';
import React, { ReactNode } from 'react';

function Layout({ children }: { children: ReactNode }) {
  return (
    <Flex sx={{ flexDirection: 'column', minHeight: '100%' }}>
      <Global
        styles={{
          html: {
            backgroundColor: '#000000',
            height: '100%',

            '*': {
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
            },

            '&.wf-active': {
              body: {
                opacity: '1 !important',
              },
            },
          },

          '#___gatsby, #___gatsby > div, body': {
            height: '100%',
          },
        }}
      />
      <SiteNavigation />
      <Box sx={{ flexGrow: 1, pt: 4, }}>{children}</Box>
      <Box sx={{ textAlign: 'center', pt: 4, pb: 4 }}>
        <Text sx={{ fontSize: 12, letterSpacing: 1.2 }}>
          Developed by{' '}
          <Link
            sx={{ color: '#FFF', textDecoration: 'none' }}
            href="http://instagram.com/jamescodes"
            target="_blank"
          >
            JamesCodes
          </Link>
        </Text>
      </Box>
    </Flex>
  );
}

export default Layout;
