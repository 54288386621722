/** @jsx jsx */
import { Box, Link as ThemedLink } from '@theme-ui/components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import Logo from 'components/atoms/Logo';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import { createRef, useEffect, useState } from 'react';
import { Button, jsx } from 'theme-ui';
import { IPage } from 'types/HighScream.types';

function SiteNavigation() {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const targetRef = createRef<HTMLDivElement>();
  const {
    allPagesYaml: { nodes: pages },
  } = useStaticQuery(graphql`
    query SiteNavigationQuery {
      allPagesYaml(sort: { fields: order, order: ASC }) {
        nodes {
          id
          slug
          title
        }
      }
    }
  `);

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }

    if (mobileMenuActive) {
      disableBodyScroll(targetRef.current);
    } else {
      enableBodyScroll(targetRef.current);
    }
  }, [mobileMenuActive]);

  useEffect(
    () => () => {
      clearAllBodyScrollLocks();
    },
    []
  );

  return (
    <Box
      ref={targetRef}
      sx={{
        display: 'flex',
        py: [3, 3, 3, 3],
        px: [4, 4, 4, 4],
        justifyContent: 'space-between',
        alignItems: 'center',
        height: [87, 87, 'auto', 'auto'],
      }}
    >
      <Box sx={{ svg: { width: [154, 154, 200, 200] } }}>
        <Link to="/">
          <Logo />
        </Link>
      </Box>
      <Button
        onClick={() => setMobileMenuActive(!mobileMenuActive)}
        sx={{
          background: 'none',
          flexDirection: 'column',
          outline: 'none',
          display: ['flex', 'flex', 'none', 'none'],

          '&:focus': {
            div: {
              backgroundColor: '#FFFFFF',
            },
          },

          div: {
            width: '28px',
            height: '5px',
            background: '#000000',
            border: 'solid 1px #FFFFFF',
            borderRadius: '5px',
            transition: 'all 100ms ease-out',

            ':nth-of-type(2)': {
              my: '3px',
            },
          },

          ...(mobileMenuActive
            ? {
                div: {
                  width: '28px',
                  height: '5px',
                  background: '#000000',
                  border: 'solid 1px #FFFFFF',
                  borderRadius: '5px',
                  transition: 'all 100ms ease-out',

                  ':nth-of-type(1)': {
                    transform: 'rotate(-45deg) translateY(3px)',
                  },

                  ':nth-of-type(2)': {
                    transform: 'rotate(45deg) translateY(-4px)',
                  },

                  ':nth-of-type(3)': {
                    opacity: 0,
                  },
                },
              }
            : {}),
        }}
      >
        <div />
        <div />
        <div />
      </Button>
      <Box
        sx={{
          position: ['fixed', 'fixed', 'relative', 'relative'],
          left: 0,
          top: [87, 87, 0, 0],
          width: ['100%', '100%', 'auto', 'auto'],
          height: ['calc(100% - 87px)', 'calc(100% - 87px)', 'auto', 'auto'],
          background: ['#000000', '#000000', 'none', 'none'],
          zIndex: [
            mobileMenuActive ? 999 : -1,
            mobileMenuActive ? 999 : -1,
            0,
            0,
          ],
          opacity: [mobileMenuActive ? 1 : 0, mobileMenuActive ? 1 : 0, 1, 1],
          transition: 'opacity 150ms ease-out',
          display: 'flex',
          flexDirection: ['column', 'column', 'row', 'row'],
          justifyContent: 'center',

          a: {
            mr: [0, 0, 4, 4],
            textAlign: ['center', 'center', 'left', 'left'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: [2, 2, 0, 0],
            mb: ['7vh', '7vh', 0, 0],

            '&:last-of-type': {
              mr: 0,
            },
          },
        }}
      >
        {pages.map(({ id, slug, title }: IPage) => (
          <Link
            activeClassName="active"
            key={id}
            sx={{ variant: 'text.siteNavigation' }}
            to={slug}
          >
            {title}
          </Link>
        ))}
        <ThemedLink
          sx={{ variant: 'text.siteNavigation' }}
          href="https://www.instagram.com/highscream/?hl=en"
          target="_blank"
          title="Find us on Instagram"
        >
          Instagram
        </ThemedLink>
      </Box>
    </Box>
  );
}

export default SiteNavigation;
