import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({
  lang = 'en',
  meta = [],
  title,
}: {
  lang?: string;
  meta?: { name: string; content: string }[];
  title?: string;
}) {
  const defaultTitle = 'High Scream';
  const description = 'Global production and events company';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@highscreamltd',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        ...meta,
      ]}
    />
  );
}

export default SEO;
